import { graphql, PageProps } from 'gatsby';
import React, { FC } from 'react';

import type { ImageBoxData } from '~src/components/elements';
import {
  BoxSection,
  FaqData,
  FaqSection,
  Hero,
  HeroData,
  InfoSection,
  InfoSectionData,
} from '~src/components/sections';
import type { SeoData } from '~src/layouts/components/seo';
import Layout from '~src/layouts/page-layout';
import type { StrapiAboutPage } from '~src/types/generated/graphql-gatsby';

type Data = {
  strapiAboutPage: StrapiAboutPage;
};

const AboutPage: FC<PageProps<Data>> = ({
  data: { strapiAboutPage },

  location,
}) => {
  const seoData = strapiAboutPage.seo as SeoData;
  const heroData = strapiAboutPage.hero as HeroData;
  const imageBoxDuoData = strapiAboutPage.imageBoxDuo as ImageBoxData[];
  const infoData = strapiAboutPage.info as InfoSectionData;
  const faqData = strapiAboutPage.faq as unknown as FaqData;

  return (
    <Layout pathname={location.pathname} seoData={seoData}>
      <Hero data={heroData} />
      <BoxSection
        isReversed
        dataTop={imageBoxDuoData[0]}
        dataBottom={imageBoxDuoData[1]}
      />
      <InfoSection data={infoData} />
      <FaqSection data={faqData} />
    </Layout>
  );
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($locale: String!) {
    strapiAboutPage(locale: { eq: $locale }) {
      seo {
        title
        description
        featuredImage {
          alt
          image {
            localFile {
              ...FeaturedImg
            }
          }
        }
      }
      hero {
        title
        coverImage {
          alt
          desktop {
            localFile {
              ...DesktopCoverImg
            }
          }
          phone {
            localFile {
              ...PhoneCoverImg
            }
          }
        }
      }
      imageBoxDuo {
        title
        text
        images {
          id
          alt
          image {
            localFile {
              ...SectionImg
            }
          }
        }
        link {
          label
          link {
            pageID
          }
        }
      }
      info {
        title
        toursTitle
        toursText
        toursLink {
          link {
            name
            pageID
          }
        }
        workTitle
        workText
        workLink {
          link {
            name
            pageID
          }
        }
        directionTitle
        directionText
        directionLink {
          link {
            name
            pageID
          }
        }
      }
      faq {
        title
        list {
          id
          question
          answer
        }
      }
    }
  }
`;
